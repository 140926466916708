import {
    Box,
    Button,
    Drawer,
    Grid,
    Header,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import Quill from 'quill'
import useStore from '../../Store'
import { Autocomplete, Chip, TextField, Typography } from '@mui/material'
import {
    generateCommentDivId,
    generateCommentForms,
    generateCommentSubMenu,
    generateDeletedCommentText,
    getProgramDeliverableMapByParentProgramGlobalAttributes,
    setCommentsDivBottomMargin,
    generateInactiveCommentText,
} from './CommentUtil'
import {
    Comment,
    COMMENT_DIV_CLASS,
    COMMENT_PANEL_MODE,
    FILTER_PAYLOAD_TYPES,
    CommentFilter,
} from './Constant'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import './RichTextCommentInput.scss'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { useAppContext } from '../../../context'
import CommentAutoRefresh from './CommentAutoRefresh'
import _ from 'lodash'
import RichTextCommentInput from './RichTextCommentInput'

interface CommentPanelProps {
    mode: COMMENT_PANEL_MODE
    onIsVisibleChange: Dispatch<SetStateAction<boolean>>
    comments: Comment[]
    getComments: () => void
    commentDeliverableOptions: any[]
    selectedRole: SelectProps.Option
    onSelectedRoleChange: Dispatch<SetStateAction<SelectProps.Option>>
    selectedProgram: SelectProps.Option
    selectedDeliverable: SelectProps.Option
    onSelectedDeliverableChange: Dispatch<SetStateAction<SelectProps.Option>>
    selectedPlan: any
    parentProgramGlobalAttributes: any[]
    allLocalProgramMap: any
    allOrgs: any[]
    allGroups: any[]
    filterOptions: CommentFilter[]
    selectedFilters: CommentFilter[]
    onSelectedFilterChanged: Dispatch<SetStateAction<CommentFilter[]>>
}

const CommentPanel = (props: CommentPanelProps) => {
    const {
        mode,
        onIsVisibleChange,
        comments,
        getComments,
        commentDeliverableOptions,
        selectedRole,
        onSelectedRoleChange,
        selectedProgram,
        selectedDeliverable,
        onSelectedDeliverableChange,
        parentProgramGlobalAttributes,
        allLocalProgramMap,
        allOrgs,
        allGroups,
        selectedPlan,
        filterOptions,
        selectedFilters,
        onSelectedFilterChanged,
    } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userProps.userAlias ?? 'user'

    const userRolesMetadata = useStore((state) => state.userRolesMetadata)
    const [userRoleSelections, setUserRoleSelections] = useState<SelectProps.Option[]>([])
    const [programMapName, deliverableMap] =
        getProgramDeliverableMapByParentProgramGlobalAttributes(parentProgramGlobalAttributes)

    useEffect(() => {
        if (!selectedPlan) {
            return
        }

        setUserRoleSelections(
            generateCommentSubMenu(userRolesMetadata, () => {}, selectedPlan).map((role) => {
                return {
                    label: role.name,
                    value: role.id,
                }
            }),
        )
    }, [userRolesMetadata, selectedPlan])

    useEffect(() => {
        setCommentsDivBottomMargin(mode)
    }, [mode])

    useEffect(() => {
        comments.forEach((comment: Comment) => {
            const quill = new Quill(`#${generateCommentDivId(comment.comment_id)}`, {
                theme: 'bubble',
                modules: {
                    toolbar: false,
                },
                readOnly: true,
            })
            let commentText = JSON.parse(comment.text)
            const commentTextArr = commentText['ops']
            if (comment?.deleted) {
                commentText = generateDeletedCommentText(commentTextArr)
            } else if (allLocalProgramMap[comment.parent_program_id]?.is_active === false) {
                commentText = generateInactiveCommentText(commentTextArr, true)
            } else if (deliverableMap[comment.deliverable_id]?.is_active === false) {
                commentText = generateInactiveCommentText(commentTextArr, false)
            }
            quill.setContents(commentText)
        })
    }, [comments])

    useEffect(() => {
        if (mode === COMMENT_PANEL_MODE.DISABLED) {
            return
        }

        getComments()
    }, [mode, selectedFilters])

    return (
        <Drawer
            header={
                <Header
                    variant={'h3'}
                    actions={
                        <Button
                            iconName='close'
                            variant='icon'
                            iconAlign={'right'}
                            onClick={() => {
                                onIsVisibleChange(false)
                            }}
                        />
                    }
                >
                    Comment
                </Header>
            }
        >
            <div className={'comment-drawer'}>
                <div className={'comment-drawer-search'}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <Autocomplete
                            multiple
                            size='small'
                            value={selectedFilters}
                            options={filterOptions}
                            groupBy={(option: CommentFilter) => option.type}
                            onChange={(event, newValue) => {
                                const planFilter = newValue.filter(
                                    (option) => option.type === FILTER_PAYLOAD_TYPES.PLAN,
                                )
                                let newFilters = [...newValue]
                                if (_.isEmpty(planFilter)) {
                                    newFilters = [
                                        ...newValue,
                                        ...filterOptions.filter(
                                            (option) => option.type === FILTER_PAYLOAD_TYPES.PLAN,
                                        ),
                                    ]
                                }
                                onSelectedFilterChanged(newFilters)
                            }}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index })
                                    return (
                                        <Chip
                                            key={key}
                                            label={option.label}
                                            {...tagProps}
                                            disabled={option.type === FILTER_PAYLOAD_TYPES.PLAN}
                                        />
                                    )
                                })
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.type === value.type && option.label === value.label
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: { fontSize: '14px' },
                                    }}
                                    label='Search Comment'
                                    placeholder='Orgs, Groups, Programs, Deliverables, Roles'
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Typography sx={{ fontSize: '12px' }}>
                                        {option.label}
                                    </Typography>
                                </li>
                            )}
                        />
                        {mode === COMMENT_PANEL_MODE.EDIT && (
                            <SpaceBetween size='m' direction='vertical'>
                                <Box variant={'h3'}>{selectedProgram.label}</Box>
                                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                                    <Box fontWeight='bold'>Your Role</Box>
                                    <Select
                                        selectedOption={selectedRole}
                                        onChange={({ detail }) =>
                                            onSelectedRoleChange(detail.selectedOption)
                                        }
                                        options={userRoleSelections}
                                        filteringType='auto'
                                        expandToViewport
                                    />
                                </Grid>
                                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                                    <SpaceBetween size='xxxs' direction='vertical'>
                                        <Box fontWeight='bold'>
                                            Deliverable<i>-optional</i>
                                        </Box>
                                    </SpaceBetween>
                                    <Select
                                        selectedOption={selectedDeliverable}
                                        onChange={({ detail }) =>
                                            onSelectedDeliverableChange(detail.selectedOption)
                                        }
                                        options={commentDeliverableOptions}
                                        filteringType='auto'
                                        expandToViewport
                                    />
                                </Grid>
                            </SpaceBetween>
                        )}
                    </SpaceBetween>
                    <hr />
                </div>
                <div id={COMMENT_DIV_CLASS} className={COMMENT_DIV_CLASS}>
                    {generateCommentForms(
                        apiClient,
                        userAlias,
                        comments,
                        getComments,
                        programMapName,
                        deliverableMap,
                        allOrgs,
                        allGroups,
                    )}
                    <CommentAutoRefresh callBack={getComments} comments={comments} />
                </div>
                {mode === COMMENT_PANEL_MODE.EDIT && (
                    <div className={'comment-drawer-sticky-footer'}>
                        <RichTextCommentInput
                            refreshComments={getComments}
                            selectedRole={selectedRole}
                            selectedDeliverable={selectedDeliverable}
                            selectedProgram={selectedProgram}
                            selectedPlan={selectedPlan}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    )
}
export default CommentPanel

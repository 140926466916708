import {Dispatch, useEffect } from "react";

const COMMENTS_PULL_FREQUENCY_MS = 60000 // Pull new comments evert 60 seconds

export type CommentAutoRefreshProps = {
    callBack: Dispatch<any>;
    comments: any
}

const CommentAutoRefresh = (props: CommentAutoRefreshProps) => {

    useEffect(() => {
        const intervalId = setInterval(props.callBack, COMMENTS_PULL_FREQUENCY_MS);
        return () => clearInterval(intervalId);
    }, [props.comments]);

    return null
}

export default CommentAutoRefresh